import {
  type Component,
  Show,
  Switch,
  Match,
  For,
  createMemo,
  createSignal,
  type Accessor,
} from "solid-js";
import { type AccessorWithLatest, createAsync } from "@solidjs/router";
import { useProductPricing } from "~/services/products";
import { useSessionContext } from "~/utils/contexts";
import type {
  InventoryByPlant,
  Product,
  ProductPriceResponse,
} from "~/services/roma-api/products/types";
import {
  ThresholdResult,
  ThresholdStatus,
  stockThreshold,
} from "~/utils/threshold";
import { Icon } from "solid-heroicons";
import { clock } from "solid-heroicons/outline";
import { Accordion } from "~/components/ui";
import { PLANTS } from "~/services/roma-api/products/types";
import { Permission } from "~/services/roma-api/account/types";

type InventoryDropdownProps = {
  sku: string;
  product: AccessorWithLatest<Product | undefined>;
};

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export const InventoryDropdown: Component<InventoryDropdownProps> = (props) => {
  const { isPartner, hasPermission } = useSessionContext();
  const [showInventory, setShowInventory] = createSignal(false);
  const pricing = createAsync(async () => {
    const data: FormattedPricing = await useProductPricing(props.sku);

    data.availableAs = Object.keys(data.Pricing);

    //       // setting 2 properties (defaultPlantLowStock / anyPlantLowStock) that will
    //       // show and hide the stock subscription button:
    data.defaultPlantLowStock = [
      ThresholdStatus.lowStock,
      ThresholdStatus.zeroStock,
    ].includes(
      stockThreshold(
        data.Inventory[data.Plant],
        props.product()?.Category!,
        props.product()?.Discontinued
      ).status
    );

    const checkAllPlants = () => {
      if (data.defaultPlantLowStock) return true;

      for (const item of Object.entries(data.Inventory)) {
        const status: ThresholdStatus = stockThreshold(
          item[1] as number,
          props.product()?.Category as string,
          props.product()?.Discontinued
        ).status;

        if (
          [ThresholdStatus.lowStock, ThresholdStatus.zeroStock].includes(status)
        ) {
          return true;
        }
      }
      return false;
    };
    data.anyPlantLowStock = checkAllPlants();

    return data;
  });

  const defaultPlantStock: Accessor<ThresholdResult> = createMemo(() => {
    // if (!isPartner()) {
    //   // return;
    //   throw new Error("TODO");
    // }
    // ? Below - wtf? this needs reworking..
    if (!pricing?.latest?.Plant || !props.product())
      return { status: ThresholdStatus.inStock } as ThresholdResult;
    return stockThreshold(
      pricing?.latest?.Inventory[pricing?.latest?.Plant],
      props.product()?.Category!,
      props.product()?.Discontinued
    );
  });

  const getNearestDate = (dateArray: string[]) => {
    if (!dateArray || dateArray.length === 0) return "TBA";
    const today = new Date();
    const minDate = new Date(new Date().setDate(today.getDate() - 14));

    const dates = dateArray.map(
      (dateString: string) => new Date(dateString.replaceAll("-", "/"))
    );

    const filteredDates = dates
      .filter((date: Date) => date.getTime() >= minDate.getTime())
      .sort((a, b) => a.getTime() - b.getTime());

    if (filteredDates.length === 0) {
      return "TBA";
    }

    const nearestDate = filteredDates[0].toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });

    return nearestDate;
  };

  return (
    <Show
      when={
        isPartner() &&
        (hasPermission([Permission.PlaceOrder, Permission.ViewPricing]) ||
          hasPermission(Permission.ViewInventory)) &&
        pricing()?.Inventory &&
        pricing()?.Plant &&
        !props.product()?.ComingSoon
      }
    >
      <Switch>
        {/* ROMA ELITE should *only* show CA stock */}
        <Match when={props.product()?.Category === "Roma Elite"}>
          <div class="grid grid-cols-product-headers items-start text-sm">
            <p class="self-start font-bold">Stock</p>
            <div class="grid grid-cols-[_40px,_max-content] font-medium text-sm">
              <p>CA</p>
              <p class="font-normal">
                {
                  stockThreshold(
                    pricing()?.Inventory?.BP01!,
                    props.product()?.Category!,
                    props.product()?.Discontinued
                  ).qtyString
                }
              </p>
            </div>
          </div>
        </Match>
        <Match
          when={
            Object.keys(pricing()?.Inventory as InventoryByPlant).length === 1
          }
        >
          <div class="grid grid-cols-product-headers items-start text-sm">
            <p class="self-start font-bold">Stock</p>
            <div class="grid grid-cols-[_40px,_auto] font-medium text-sm">
              <p>{PLANTS[pricing()?.Plant!].abbr}</p>
              <div class="flex items-center justify-between">
                <span>{defaultPlantStock().dropdownContent}</span>
                <Show
                  when={
                    pricing()?.InventoryETA[pricing()?.Plant!] &&
                    [
                      ThresholdStatus.lowStock,
                      ThresholdStatus.zeroStock,
                      // !HERE
                    ].includes(defaultPlantStock().status)
                  }
                >
                  <div class="inline-flex items-center text-xs ml-1 text-roma-medium-grey">
                    <Icon path={clock} class="w-5 h-5 mr-1" />
                    <p>
                      Re-stock estimate{" "}
                      {getNearestDate(
                        pricing()?.InventoryETA?.[
                          pricing()?.Plant as keyof typeof PLANTS
                        ] as string[]
                      )}
                    </p>
                  </div>
                </Show>
              </div>
              <Show
                when={
                  pricing()?.defaultPlantLowStock &&
                  !props.product()?.Discontinued
                }
              >
                <div class="col-span-full">
                  {/* !TODO */}
                  <div class="border border-orange-500 text-orange-500">
                    TODO: Stock Subscribe Button
                  </div>
                  {/* <StockSubscribeButton
                    inventory={pricing()}
                    product={props.product()}
                  /> */}
                </div>
              </Show>
            </div>
          </div>
        </Match>
        <Match when={Object.keys(pricing()?.Inventory!).length > 1}>
          <div class="grid grid-cols-product-headers items-start text-sm">
            <p class="self-start font-bold">Stock</p>
            <Accordion
              name="stock"
              label={
                <div class="grid grid-cols-[_40px,_auto] font-medium text-sm">
                  <p>{PLANTS[pricing()?.Plant!].abbr}</p>
                  <div class="flex items-center justify-between">
                    <span>{defaultPlantStock().dropdownContent}</span>
                    <Show
                      when={
                        pricing()?.InventoryETA[
                          pricing()?.Plant as keyof typeof PLANTS
                        ] &&
                        [
                          ThresholdStatus.lowStock,
                          ThresholdStatus.zeroStock,
                        ].includes(defaultPlantStock().status)
                      }
                    >
                      <div class="inline-flex items-center text-xs ml-1 text-roma-medium-grey">
                        <Icon path={clock} class="w-5 h-5 mr-1" />
                        <p>
                          Re-stock estimate{" "}
                          {getNearestDate(
                            pricing()?.InventoryETA?.[
                              pricing()?.Plant as keyof typeof PLANTS
                            ] as string[]
                          )}
                        </p>
                      </div>
                    </Show>
                  </div>
                </div>
              }
              footer={
                <Show
                  when={
                    (pricing()?.defaultPlantLowStock ||
                      (pricing()?.anyPlantLowStock && showInventory())) &&
                    !props.product()?.Discontinued
                  }
                >
                  <div class="border border-orange-500 text-orange-500">
                    TODO: Stock Subscribe Button
                  </div>
                  {/* <StockSubscribeButton
                    inventory={pricing()}
                    product={props.product()}
                  /> */}
                </Show>
              }
              icon="Chevron"
              iconRotation="180"
              childClass="text-sm"
              onOpen={setShowInventory}
            >
              <ul class="flex flex-col  text-sm font-bold ">
                <Show when={pricing()?.Inventory}>
                  <For
                    each={Object.keys(pricing()?.Inventory as InventoryByPlant)}
                  >
                    {(plant) => (
                      <li class="grid grid-cols-[_40px,_auto]  py-3 items-center  font-normal text-roma-medium-grey border-b last:border-none">
                        <p>{PLANTS[plant as keyof typeof PLANTS].abbr}</p>
                        <div class="w-full flex justify-between">
                          <div class="flex items-center">
                            <p>
                              {
                                stockThreshold(
                                  pricing()?.Inventory[
                                    plant as keyof typeof PLANTS
                                  ]!,
                                  props.product()?.Category!,
                                  props.product()?.Discontinued
                                ).qtyString
                              }
                            </p>
                            <Show
                              when={
                                pricing()?.InventoryETA?.[
                                  plant as keyof typeof PLANTS
                                ] &&
                                [
                                  ThresholdStatus.lowStock,
                                  ThresholdStatus.zeroStock,
                                ].includes(
                                  stockThreshold(
                                    pricing()?.Inventory[
                                      plant as keyof typeof PLANTS
                                    ]!,
                                    props.product()?.Category!,
                                    props.product()?.Discontinued
                                  ).status
                                )
                              }
                            >
                              <div class="flex items-center text-xs">
                                <Icon path={clock} class="w-5 h-5 mr-1" />
                                <p>
                                  Re-stock estimate{" "}
                                  {getNearestDate(
                                    pricing()?.InventoryETA?.[
                                      plant as keyof typeof PLANTS
                                    ] as string[]
                                  )}
                                </p>
                              </div>
                            </Show>
                          </div>
                          <div class="text-right font-light">
                            <Switch>
                              <Match
                                when={
                                  stockThreshold(
                                    pricing()?.Inventory[
                                      plant as keyof typeof PLANTS
                                    ]!,
                                    props.product()?.Category!,
                                    props.product()?.Discontinued
                                  ).status === ThresholdStatus.inStock
                                }
                              >
                                <p>In Stock</p>
                              </Match>
                              <Match
                                when={
                                  stockThreshold(
                                    pricing()?.Inventory[
                                      plant as keyof typeof PLANTS
                                    ]!,
                                    props.product()?.Category!,
                                    props.product()?.Discontinued
                                  ).status === ThresholdStatus.lowStock
                                }
                              >
                                <p class="text-orange-400">Low Stock</p>
                              </Match>
                              <Match
                                when={
                                  stockThreshold(
                                    pricing()?.Inventory[
                                      plant as keyof typeof PLANTS
                                    ]!,
                                    props.product()?.Category!,
                                    props.product()?.Discontinued
                                  ).status === ThresholdStatus.zeroStock
                                }
                              >
                                <p class="text-orange-600">Sold Out</p>
                              </Match>
                            </Switch>
                          </div>
                        </div>
                      </li>
                    )}
                  </For>
                  <p class="text-xs text-roma-medium-grey font-light pt-3">
                    * Alternate distribution center can be selected on order
                    page
                  </p>
                </Show>
              </ul>
            </Accordion>
          </div>
        </Match>
      </Switch>
    </Show>
  );
};
