import { Show, type Component } from "solid-js";
import fractionString from "~/utils/fraction";
import { Product } from "~/services/roma-api/products/types";
import { AccessorWithLatest } from "@solidjs/router";

export const ProductDimensionsDetail: Component<{
  product: AccessorWithLatest<Product | undefined>;
}> = (props) => {
  return (
    <Show when={props.product()} keyed>
      {(prod) => (
        <div class="grid grid-cols-product-headers items-center text-sm  ">
          <p class="font-bold">Size</p>
          <div class="flex items-baseline gap-3 font-medium text-sm tracking-wider">
            <Show when={prod.Floater}>
              <Show when={prod?.Depth}>
                <p title="Depth">
                  D<span class="fraction">{fractionString(prod?.Depth)}</span>
                </p>
              </Show>
              <Show when={prod?.Face}>
                <p title="Face">
                  F<span class="fraction">{fractionString(prod?.Face)}</span>
                </p>
              </Show>
            </Show>
            <p title="Width">
              W<span class="fraction">{fractionString(prod.Width)}</span>
            </p>
            <p title="Height">
              H<span class="fraction">{fractionString(prod.Height)}</span>
            </p>
            <Show when={prod.Rabbet > 0}>
              <p title="Rabbet">
                R<span class="fraction">{fractionString(prod.Rabbet)}</span>
              </p>
            </Show>
          </div>
        </div>
      )}
    </Show>
  );
};
