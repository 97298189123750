import {
  LENGTH,
  JOIN,
  CHOP,
  RAIL,
  PHOTOFRAME,
  GALLERYFRAME,
  BOX,
  CONTRACT,
  CORNERSAMPLE,
  MIRROR,
} from "~/utils/products";

export type ListResponse<T> = {
  Limit: number;
  Offset: number;
  Total: number;
  Results: T[];
};

export type ProductFilters = {
  limit?: number;
  skus?: string[] | string;
  collection?: string[] | string;
  category?: string[] | string;
  style?: string[] | string;
  profile?: string[] | string;
  colour?: string[] | string;
  availableAs?: string; // remove?
  available_as?: string;
  available_list?: boolean;
  suggestions?: boolean;
  on_sale?: boolean;
  sort?:
    | "sku"
    | "is_new"
    | "alpha"
    | "colour"
    | "featured"
    | "high_low"
    | "low_high";
  status?: ProductStatus | ProductStatus[];
  min_width?: number;
  max_width?: number;
  min_height?: number;
  max_height?: number;
  min_rabbet?: number;
  max_rabbet?: number;
  search?: string;
  // finishType?: never;
  // coming_soon?: never;
  // on_sale?: never;
  // type?: never;
  fields?: Array<keyof Product> | keyof Product;
  type?: "galleryFrame" | "photoFrame" | "moulding";
  counts?: boolean;
};

type ProductStatus = "active" | "discontinued" | "discontinuing" | "archive" | "is_new";

export type Product = {
  Name: string;
  SKU: string;
  Active: boolean;
  Discontinuing: boolean;
  Discontinued: boolean;
  IsNew: boolean;
  ComingSoon: boolean;
  ArrivalDate?: string;
  OnSale: boolean;
  OnSaleNote: string;
  Floater: boolean;
  Description: string;
  Category: string;
  Collection: string;
  Profile: string;
  ProfileFamily: string;
  Colour: string;
  Type: string;
  ColourType: string;
  ColourDescription: string;
  Finish: string;
  FinishType: string;
  Height: number;
  Width: number;
  Rabbet: number;
  Face: number;
  Depth: number;
  ProductType: string;
  Style: string;
  SEOTile: string;
  SEODescription: string;
  SEOKeywords: string;
  JoinCode: string;
  UpdatedAt: string;
  Video: string;
  Images: string[];
  AvailableIn: string[];
  AvailableAs: string[];
  AvailableList: Pick<
    Product,
    "Name" | "SKU" | "Colour" | "ColourType" | "ColourDescription"
  >[];
  Price: number;
};

export type PartialProduct<
  T extends Array<keyof Product> | keyof Product | undefined
> = T extends Array<keyof Product>
  ? Pick<Product, T[number]>
  : T extends keyof Product
  ? Pick<Product, T>
  : Product;

export const PLANTS = {
  BP01: { name: "Canada", abbr: "CAN" },
  BP03: { name: "Los Angeles", abbr: "LA" },
  BP04: { name: "New Jersey", abbr: "NJ" },
  BP05: { name: "Atlanta", abbr: "AT" },
} as const;

type PlantCodeKeys = keyof typeof PLANTS;

export type InventoryByPlant = {
  [key in PlantCodeKeys]: number;
};

type ETAByPlant = {
  [key in PlantCodeKeys]?: string[];
};

type AmountAndDiscount = {
  Amount: number;
  Discount?: number;
};

export type ProductPriceResponse = {
  Currency: "USD" | "CAD";
  Inventory: InventoryByPlant;
  InventoryETA: ETAByPlant;
  Plant: PlantCodeKeys;
  Pricing: {
    length?: AmountAndDiscount;
    chop?: AmountAndDiscount;
    join?: { Code: string };
    rail?: AmountAndDiscount;
    photoFrame?: AmountAndDiscount;
    galleryFrame?: AmountAndDiscount;
    contract?: AmountAndDiscount;
    cornerSample?: AmountAndDiscount;
    box?: AmountAndDiscount & { Quantity: number };
  };
};

export type ProductInfoResponse = Pick<
  ProductPriceResponse,
  "InventoryETA" | "Plant" | "Inventory"
> & {
  Pricing: {
    Currency: "CAD" | "USD";
    Base: number;
    BaseUnit: string;
    Rail?: number;
    Chop?: number;
    RailUnit?: string;
    ChopUnit?: string;
  };
};
