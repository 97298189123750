import { type Component, Show } from "solid-js";
import { type AccessorWithLatest, createAsync } from "@solidjs/router";
import { type Product } from "~/services/roma-api/products/types";
import { useProductPricing } from "~/services/products";
import { BOX } from "~/utils/products";

type BoxQtyProps = {
  sku: string;
  product: AccessorWithLatest<Product | undefined>;
};

export const BoxQty: Component<BoxQtyProps> = (props) => {
  const pricing = createAsync(() => useProductPricing(props.sku));

  return (
    <Show
      when={
        props.product()?.AvailableAs.includes(BOX) && pricing()?.Pricing[BOX]
      }
    >
      <div class="grid grid-cols-product-headers items-center text-sm  ">
        <p class="font-bold">Box Qty</p>
        <div class=" font-medium text-sm tracking-wider">
          <p>{pricing()?.Pricing[BOX]?.Quantity} ft. </p>
        </div>
      </div>
    </Show>
  );
};
