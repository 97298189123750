import { type Component } from "solid-js";
import { Accordion } from "~/components/ui";

export const DefaultCareInstr: Component = () => {
  return (
    <Accordion
      name="care-instructions"
      label="Care Instructions"
      labelClass="font-bold"
      icon="Chevron"
      childClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
    >
      <p>
        To clean your frame, please use a soft, dry cloth with gentle pressure.
        Avoid using harsh chemicals or abrasives that may cause damage. With
        proper care, you can help preserve your frame's beauty and longevity.
      </p>
    </Accordion>
  );
};
